import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg height={22} viewBox="0 0 22 22" width={22} {...props}>
      <text
        fillRule="evenodd"
        fontFamily="AppleColorEmoji, Apple Color Emoji"
        fontSize={20}
        letterSpacing={0.5}
        transform="translate(-84 -31)"
      >
        <tspan x={84} y={50}>
          {'\uD83D\uDC9A'}
        </tspan>
      </text>
    </svg>
  );
}

export default SvgComponent;
