import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={35} height={45} viewBox="0 0 40 50" {...props}>
      <title>{'793788F4-979C-4E42-9A1A-7127CCF4FBC8'}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M31.619 6.214l-3.115 13.393c-1.79-.7-3.504-1.09-5.139-1.09-3.971 0-6.852 2.18-6.852 6.151v21.336H2.497V6.292h13.004l.467 4.75c2.414-3.582 6.23-5.762 10.745-5.762 1.791 0 3.426.311 4.906.934"
          fill="#73C993"
        />
        <path
          d="M31.619 6.214l-3.115 13.393c-1.79-.7-3.504-1.09-5.139-1.09-3.971 0-6.852 2.18-6.852 6.151v21.336H2.497V6.292h13.004l.467 4.75c2.414-3.582 6.23-5.762 10.745-5.762 1.791 0 3.426.311 4.906.934z"
          stroke="#73C993"
          strokeWidth={0.5}
        />
        <path
          d="M29.48 1.934l-3.116 13.393c-1.79-.7-3.504-1.09-5.139-1.09-3.97 0-6.852 2.18-6.852 6.152v21.335H.357V2.012h13.004l.467 4.75C16.242 3.18 20.058 1 24.574 1c1.79 0 3.426.312 4.905.934"
          fill="#FFF"
        />
        <path
          d="M29.48 1.934l-3.116 13.393c-1.79-.7-3.504-1.09-5.139-1.09-3.97 0-6.852 2.18-6.852 6.152v21.335H.357V2.012h13.004l.467 4.75C16.242 3.18 20.058 1 24.574 1c1.79 0 3.426.312 4.905.934z"
          stroke="#73C993"
          strokeWidth={0.5}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
