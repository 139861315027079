import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import styled, { css } from 'styled-components';
import { Container, TitleContainer } from 'ui/containers';
import backgroundManifest from '../assets/images/backgroundManifest.png';
import doodleLarge from '../assets/images/doodleLarge.png';
import {
  Paragraph as RawParagraph,
  media,
  Button,
  Buttons,
} from 'ui';
import PageContext from 'contexts/page';
import { useTranslation } from 'react-i18next';
import Respect from '../assets/images/Respect.js';
import ArticleCardsCarousel from 'components/ArticleCardsCarousel';
import { Link } from 'gatsby';
import Respect1 from '../assets/images/Respect1.js';
import Respect2 from '../assets/images/Respect2.js';
import Respect3 from '../assets/images/Respect3.js';
import Respect4 from '../assets/images/Respect4.js';
import Respect5 from '../assets/images/Respect5.js';
import Respect6 from '../assets/images/Respect6.js';
import Respect7 from '../assets/images/Respect7.js';
import GreenHeart from '../assets/images/GreenHeart.js';
import HeroHeader from '../components/HeroHeader';

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${media.tablet`
    flex-direction: row;
  `}
`;

const LeftContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: left;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ${media.tablet`
  text-align: left;
  `}
`;

const RightContainer = styled.div`
  display: none;
  width: 100%;
  max-width: 100%;

  ${media.tablet`
    display: block;
    margin-left: ${({ theme }) => theme.spacing(5)};
    padding-top: ${({ theme }) => theme.spacing(5)};
    max-width: 200px;
  `};

  ${media.medium`
    margin-left: ${({ theme }) => theme.spacing(7)};
    padding-top: ${({ theme }) => theme.spacing(7)};
    max-width: 250px;
  `};

  ${media.desktop`
    margin-left: ${({ theme }) => theme.spacing(10)};
    padding-top: ${({ theme }) => theme.spacing(10)};
    max-width: 300px;
  `};
`;

const BackgroundWrapper = styled.div`
  background-image: url('${doodleLarge}');
  margin-top: ${({ theme }) => theme.spacing(3)};

  padding-bottom: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
      padding-bottom: ${({ theme }) => theme.spacing(2)};
      margin-left: 0;
  `}
`;

const Section = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0
    ${({ theme }) => theme.spacing()};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  ${media.desktop`
    margin-right: auto;
    margin-left: auto;
    margin-top: ${({ theme }) => theme.spacing(2)};
    width: 1200px;
    max-width: 100%;
  `};
`;

const LetterLine = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing()} !important;
`;

const Subtitle = styled.h2`
  ${({ theme }) => theme.fontStyles.h2}
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const Title = styled.h1`
  ${({ theme }) => theme.fontStyles.h1}
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const BlogSubTitle = styled.p`
  min-width: 160px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

const Paragraph = styled(RawParagraph)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.fontStyles.bodyLarger}

  span {
    font-weight: 700;
  }

  ${props =>
    props.isTabed &&
    css`
      padding-left: ${({ theme }) => theme.spacing()};
    `}
`;

const RespectParagraph = styled.p`
  ${({ theme }) => theme.fontStyles.bodyLarger}
  flex: 3;
  margin-left: 0;

  ${media.tablet`
    flex: 4;
  `}

  ${media.medium`
    flex: 5;
  `}

  ${media.desktop`
    flex: 6;
  `}
`;

const HasLink = styled.span`
  color: ${({ theme }) => theme.secondary} !important;
  text-decoration: underline;
  font-weight: 400 !important;
`;

export const query = graphql`
  query($language: String!) {
    allBlog(limit: 10, filter: { language: { eq: $language } }) {
      nodes {
        id
        slug
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
        }
        thematic {
          displayName
        }
      }
    }
  }
`;

const Manifesto = ({
  data: {
    allBlog: { nodes: articles },
  },
}) => {
  const { getLanguagePrefix } = useContext(PageContext);

  const { t } = useTranslation();

  return (
    <Layout hasSearchBar>
      <SEO title={t('pageTitle.manifeste')} />
      <HeroHeader
        title={t('header.aboutUs')}
        image={backgroundManifest}
      />
      <Container>
        <SplitContainer>
          <LeftContainer>
            <Title>{t('manifest.mainTitle.l1')}</Title>
            <span style={{ fontSize: '24px' }}>
              {t('manifest.mainTitle.l2')}
            </span>
            <Paragraph>
              {t('manifest.p1.l1')} <span> {t('manifest.p1.l2')}</span>{' '}
              {t('manifest.p1.l3')} <span> {t('manifest.p1.l4')}</span>
            </Paragraph>
            <Paragraph>
              {t('manifest.p2.l1')} <span> {t('manifest.p2.l2')}</span>{' '}
              {t('manifest.p2.l3')}
            </Paragraph>
            <Paragraph>{t('manifest.p3')}</Paragraph>
            <Paragraph>{t('manifest.p4')}</Paragraph>
            <Paragraph>{t('manifest.p5')}</Paragraph>
            <Paragraph>
              {t('manifest.p6.l1')}
              <span>{t('manifest.p6.l2')}</span>
            </Paragraph>
            <Subtitle>{t('manifest.subtitle1')}</Subtitle>
            <Paragraph isTabed>
              <span>{t('manifest.p7.l1')}</span> {t('manifest.p7.l2')}{' '}
              <HasLink
                as={Link}
                to={getLanguagePrefix(t('url.sustainable'))}
                state={{ slug: 'partners' }}
              >
                {t('manifest.p7.l3')}
              </HasLink>
            </Paragraph>
            <Paragraph isTabed>
              <span>{t('manifest.p8.l1')}</span> {t('manifest.p8.l2')}{' '}
              <HasLink
                to={getLanguagePrefix(t('url.sustainable'))}
                state={{ slug: 'certifications' }}
                as={Link}
              >
                {t('manifest.p8.l3')}
              </HasLink>{' '}
              {t('manifest.p8.l4')}{' '}
              <HasLink
                as={Link}
                to={getLanguagePrefix(t('url.sustainable'))}
                state={{ slug: 'us' }}
              >
                {t('manifest.p8.l5')}
              </HasLink>{' '}
              {t('manifest.p8.l6')}
            </Paragraph>
            <Paragraph isTabed>
              <span>{t('manifest.p9.l1')}</span> {t('manifest.p9.l2')}
            </Paragraph>
            <Subtitle>{t('manifest.subtitle2')}</Subtitle>
            <LetterLine>
              <Respect1 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter1')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect2 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter2')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect3 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter3')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect4 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter4')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect5 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter5')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect6 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter6')}</RespectParagraph>
            </LetterLine>
            <LetterLine>
              <Respect7 style={{ flex: 1 }} />
              <RespectParagraph>{t('manifest.letter7')}</RespectParagraph>
            </LetterLine>
            <Subtitle>
              {t('manifest.subtitle3.l1')} <br />
              <HasLink
                style={{ paddingTop: '16px' }}
                as={Link}
                to="/?register=true"
              >
                {t('manifest.subtitle3.l2')}
              </HasLink>{' '}
              <span>
                <GreenHeart />
              </span>
            </Subtitle>
          </LeftContainer>
          <RightContainer>
            <Respect />
          </RightContainer>
        </SplitContainer>
      </Container>
      <BackgroundWrapper>
        <Section centered>
          <TitleContainer bold>{t('home.blog.title')}</TitleContainer>
          <BlogSubTitle>{t('home.blog.subtitle')}</BlogSubTitle>
          <ArticleCardsCarousel articles={articles} />
        </Section>
        <Buttons hasTopMargin>
          <Button
            variant="secondary"
            as={Link}
            to={getLanguagePrefix(t('url.articles'))}
          >
            {t('home.blog.button')}
          </Button>
        </Buttons>
      </BackgroundWrapper>
    </Layout>
  );
};

export default Manifesto;
